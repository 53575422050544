export const coefficients = [
  { 0: 0 },
  { 0.1: 0.0012 },
  { 0.2: 0.0025 },
  { 0.25: 0.003 },
  { 0.3: 0.0037 },
  { 0.4: 0.0049 },
  { 0.5: 0.006 },
  { 0.6: 0.0074 },
  { 0.7: 0.0086 },
  { 0.75: 0.009 },
  { 0.8: 0.0098 },
  { 0.9: 0.011 },
  { 1: 0.012 },
  { 1.1: 0.0134 },
  { 1.2: 0.0146 },
  { 1.25: 0.015 },
  { 1.3: 0.0157 },
  { 1.4: 0.0169 },
  { 1.5: 0.018 },
  { 1.6: 0.0192 },
  { 1.7: 0.0204 },
  { 1.75: 0.021 },
  { 1.8: 0.0215 },
  { 1.9: 0.0227 },
  { 2: 0.024 },
  { 2.1: 0.0249 },
  { 2.2: 0.0261 },
  { 2.25: 0.027 },
  { 2.3: 0.0272 },
  { 2.4: 0.0283 },
  { 2.5: 0.029 },
  { 2.6: 0.0305 },
  { 2.7: 0.0316 },
  { 2.75: 0.032 },
  { 2.8: 0.0327 },
  { 2.9: 0.0338 },
  { 3: 0.035 },
  { 3.1: 0.036 },
  { 3.2: 0.037 },
  { 3.25: 0.038 },
  { 3.3: 0.0381 },
  { 3.4: 0.0392 },
  { 3.5: 0.04 },
  { 3.6: 0.0413 },
  { 3.7: 0.0423 },
  { 3.75: 0.043 },
  { 3.8: 0.0434 },
  { 3.9: 0.0444 },
  { 4: 0.045 },
  { 4.1: 0.0465 },
  { 4.2: 0.0475 },
  { 4.25: 0.048 },
  { 4.3: 0.0485 },
  { 4.4: 0.0495 },
  { 4.5: 0.051 },
  { 4.6: 0.0516 },
  { 4.7: 0.0526 },
  { 4.75: 0.053 },
  { 4.8: 0.0536 },
  { 4.9: 0.0546 },
  { 5: 0.056 },
  { 5.1: 0.0565 },
  { 5.2: 0.0575 },
  { 5.25: 0.058 },
  { 5.3: 0.0585 },
  { 5.4: 0.0595 },
  { 5.5: 0.06 },
  { 5.6: 0.0614 },
  { 5.7: 0.0624 },
  { 5.75: 0.063 },
  { 5.8: 0.0633 },
  { 5.9: 0.0643 },
  { 6: 0.065 },
  { 6.1: 0.0662 },
  { 6.2: 0.0671 },
  { 6.25: 0.068 },
  { 6.3: 0.068 },
  { 6.4: 0.069 },
  { 6.5: 0.07 },
  { 6.6: 0.0708 },
  { 6.7: 0.0717 },
  { 6.75: 0.072 },
  { 6.8: 0.0726 },
  { 6.9: 0.0736 },
  { 7: 0.074 },
  { 7.1: 0.0754 },
  { 7.2: 0.0763 },
  { 7.25: 0.077 },
  { 7.3: 0.0772 },
  { 7.4: 0.0781 },
  { 7.5: 0.079 },
  { 7.6: 0.0798 },
  { 7.7: 0.0807 },
  { 7.75: 0.081 },
  { 7.8: 0.0816 },
  { 7.9: 0.0825 },
  { 8: 0.083 },
  { 8.1: 0.0842 },
  { 8.2: 0.0851 },
  { 8.25: 0.085 },
  { 8.3: 0.0859 },
  { 8.4: 0.0868 },
  { 8.5: 0.088 },
  { 8.6: 0.0885 },
  { 8.7: 0.0893 },
  { 8.75: 0.09 },
  { 8.8: 0.0902 },
  { 8.9: 0.091 },
  { 9: 0.092 },
  { 9.1: 0.0927 },
  { 9.2: 0.0935 },
  { 9.25: 0.094 },
  { 9.3: 0.0943 },
  { 9.4: 0.0951 },
  { 9.5: 0.096 },
  { 9.6: 0.0968 },
  { 9.7: 0.0976 },
  { 9.75: 0.098 },
  { 9.8: 0.0984 },
  { 9.9: 0.0992 },
  { 10: 0.1 },
  { 10.1: 0.1008 },
  { 10.2: 0.1016 },
  { 10.25: 0.102 },
  { 10.3: 0.1024 },
  { 10.4: 0.1032 },
  { 10.5: 0.104 },
  { 10.6: 0.1047 },
  { 10.7: 0.1055 },
  { 10.75: 0.106 },
  { 10.8: 0.1063 },
  { 10.9: 0.1071 },
  { 11: 0.108 },
  { 11.1: 0.1086 },
  { 11.2: 0.1094 },
  { 11.25: 0.11 },
  { 11.3: 0.1101 },
  { 11.4: 0.1109 },
  { 11.5: 0.112 },
  { 11.6: 0.1124 },
  { 11.7: 0.1132 },
  { 11.75: 0.114 },
  { 11.8: 0.1139 },
  { 11.9: 0.1146 },
  { 12: 0.115 },
  { 12.1: 0.1161 },
  { 12.2: 0.1169 },
  { 12.25: 0.117 },
  { 12.3: 0.1176 },
  { 12.4: 0.1183 },
  { 12.5: 0.119 },
  { 12.6: 0.1198 },
  { 12.7: 0.1205 },
  { 12.75: 0.121 },
  { 12.8: 0.1212 },
  { 12.9: 0.1219 },
  { 13: 0.123 },
  { 13.1: 0.1234 },
  { 13.2: 0.1241 },
  { 13.25: 0.124 },
  { 13.3: 0.1248 },
  { 13.4: 0.1255 },
  { 13.5: 0.126 },
  { 13.6: 0.1269 },
  { 13.7: 0.1276 },
  { 13.75: 0.128 },
  { 13.8: 0.1283 },
  { 13.9: 0.1289 },
  { 14: 0.13 },
  { 14.1: 0.1303 },
  { 14.2: 0.131 },
  { 14.25: 0.131 },
  { 14.3: 0.1317 },
  { 14.4: 0.1324 },
  { 14.5: 0.133 },
  { 14.6: 0.1337 },
  { 14.7: 0.1344 },
  { 14.75: 0.135 },
  { 14.8: 0.135 },
  { 14.9: 0.1357 },
  { 15: 0.136 },
  { 15.1: 0.137 },
  { 15.2: 0.1377 },
  { 15.25: 0.138 },
  { 15.3: 0.1383 },
  { 15.4: 0.139 },
  { 15.5: 0.14 },
  { 15.6: 0.1403 },
  { 15.7: 0.1409 },
  { 15.75: 0.141 },
  { 15.8: 0.1416 },
  { 15.9: 0.1422 },
  { 16: 0.143 },
  { 16.1: 0.1435 },
  { 16.2: 0.1441 },
  { 16.25: 0.144 },
  { 16.3: 0.1448 },
  { 16.4: 0.1454 },
  { 16.5: 0.146 },
  { 16.6: 0.1466 },
  { 16.7: 0.1473 },
  { 16.75: 0.148 },
  { 16.8: 0.1479 },
  { 16.9: 0.1485 },
  { 17: 0.149 },
  { 17.1: 0.1497 },
  { 17.2: 0.1503 },
  { 17.25: 0.151 },
  { 17.3: 0.151 },
  { 17.4: 0.1516 },
  { 17.5: 0.152 },
  { 17.6: 0.1528 },
  { 17.7: 0.1534 },
  { 17.75: 0.154 },
  { 17.8: 0.154 },
  { 17.9: 0.1546 },
  { 18: 0.155 },
  { 18.1: 0.1558 },
  { 18.2: 0.1564 },
  { 18.25: 0.157 },
  { 18.3: 0.1569 },
  { 18.4: 0.1575 },
  { 18.5: 0.158 },
  { 18.6: 0.1587 },
  { 18.7: 0.1593 },
  { 18.75: 0.16 },
  { 18.8: 0.1599 },
  { 18.9: 0.1604 },
  { 19: 0.161 },
  { 19.1: 0.1616 },
  { 19.2: 0.1622 },
  { 19.25: 0.162 },
  { 19.3: 0.1627 },
  { 19.4: 0.1633 },
  { 19.5: 0.164 },
  { 19.6: 0.1644 },
  { 19.7: 0.165 },
  { 19.75: 0.165 },
  { 19.8: 0.1656 },
  { 19.9: 0.1661 },
  { 20: 0.167 },
  { 20.1: 0.1672 },
  { 20.2: 0.1678 },
  { 20.25: 0.168 },
  { 20.3: 0.1683 },
  { 20.4: 0.1689 },
  { 20.5: 0.169 },
  { 20.6: 0.17 },
  { 20.7: 0.1705 },
  { 20.75: 0.171 },
  { 20.8: 0.1711 },
  { 20.9: 0.1716 },
  { 21: 0.172 },
  { 21.1: 0.1727 },
  { 21.2: 0.1732 },
  { 21.25: 0.173 },
  { 21.3: 0.1737 },
  { 21.4: 0.1743 },
  { 21.5: 0.175 },
  { 21.6: 0.1753 },
  { 21.7: 0.1759 },
  { 21.75: 0.176 },
  { 21.8: 0.1764 },
  { 21.9: 0.1769 },
  { 22: 0.177 },
  { 22.1: 0.1779 },
  { 22.2: 0.1785 },
  { 22.25: 0.179 },
  { 22.3: 0.179 },
  { 22.4: 0.1795 },
  { 22.5: 0.18 },
  { 22.6: 0.1805 },
  { 22.7: 0.181 },
  { 22.75: 0.181 },
  { 22.8: 0.1815 },
  { 22.9: 0.182 },
  { 23: 0.183 },
  { 23.1: 0.183 },
  { 23.2: 0.1835 },
  { 23.25: 0.184 },
  { 23.3: 0.184 },
  { 23.4: 0.1845 },
  { 23.5: 0.185 },
  { 23.6: 0.1855 },
  { 23.7: 0.186 },
  { 23.75: 0.186 },
  { 23.8: 0.1865 },
  { 23.9: 0.187 },
  { 24: 0.188 },
  { 24.1: 0.188 },
  { 24.2: 0.1885 },
  { 24.25: 0.189 },
  { 24.3: 0.189 },
  { 24.4: 0.1894 },
  { 24.5: 0.19 },
  { 24.6: 0.1904 },
  { 24.7: 0.1909 },
  { 24.75: 0.191 },
  { 24.8: 0.1914 },
  { 24.9: 0.1918 },
  { 25: 0.192 },
  { 25.1: 0.1928 },
  { 25.2: 0.1933 },
  { 25.25: 0.193 },
  { 25.3: 0.1937 },
  { 25.4: 0.1942 },
  { 25.5: 0.195 },
  { 25.6: 0.1951 },
  { 25.7: 0.1956 },
  { 25.75: 0.196 },
  { 25.8: 0.196 },
  { 25.9: 0.1965 },
  { 26: 0.197 },
  { 26.1: 0.1974 },
  { 26.2: 0.1979 },
  { 26.25: 0.198 },
  { 26.3: 0.1983 },
  { 26.4: 0.1988 },
  { 26.5: 0.199 },
  { 26.6: 0.1997 },
  { 26.7: 0.2001 },
  { 26.75: 0.2 },
  { 26.8: 0.2006 },
  { 26.9: 0.201 },
  { 27: 0.201 },
  { 27.1: 0.2019 },
  { 27.2: 0.2024 },
  { 27.25: 0.203 },
  { 27.3: 0.2028 },
  { 27.4: 0.2033 },
  { 27.5: 0.204 },
  { 27.6: 0.2041 },
  { 27.7: 0.2046 },
  { 27.75: 0.205 },
  { 27.8: 0.205 },
  { 27.9: 0.2054 },
  { 28: 0.206 },
  { 28.1: 0.2063 },
  { 28.2: 0.2067 },
  { 28.25: 0.207 },
  { 28.3: 0.2072 },
  { 28.4: 0.2076 },
  { 28.5: 0.208 },
  { 28.6: 0.2085 },
  { 28.7: 0.2089 },
  { 28.75: 0.209 },
  { 28.8: 0.2093 },
  { 28.9: 0.2097 },
  { 29: 0.21 },
  { 29.1: 0.2106 },
  { 29.2: 0.211 },
  { 29.25: 0.211 },
  { 29.3: 0.2114 },
  { 29.4: 0.2118 },
  { 29.5: 0.212 },
  { 29.6: 0.2126 },
  { 29.7: 0.2131 },
  { 29.75: 0.213 },
  { 29.8: 0.2135 },
  { 29.9: 0.2139 },
  { 30: 0.214 },
  { 30.1: 0.2147 },
  { 30.2: 0.2151 },
  { 30.25: 0.215 },
  { 30.3: 0.2155 },
  { 30.4: 0.2159 },
  { 30.5: 0.216 },
  { 30.6: 0.2167 },
  { 30.7: 0.2171 },
  { 30.75: 0.217 },
  { 30.8: 0.2175 },
  { 30.9: 0.2179 },
  { 31: 0.218 },
  { 31.1: 0.2187 },
  { 31.2: 0.2191 },
  { 31.25: 0.219 },
  { 31.3: 0.2195 },
  { 31.4: 0.2199 },
  { 31.5: 0.22 },
  { 31.6: 0.2207 },
  { 31.7: 0.2211 },
  { 31.75: 0.221 },
  { 31.8: 0.2214 },
  { 31.9: 0.2218 },
  { 32: 0.222 },
  { 32.1: 0.2226 },
  { 32.2: 0.223 },
  { 32.25: 0.223 },
  { 32.3: 0.2234 },
  { 32.4: 0.2238 },
  { 32.5: 0.224 },
  { 32.6: 0.2245 },
  { 32.7: 0.2249 },
  { 32.75: 0.225 },
  { 32.8: 0.2253 },
  { 32.9: 0.2257 },
  { 33: 0.226 },
  { 33.1: 0.2264 },
  { 33.2: 0.2268 },
  { 33.25: 0.227 },
  { 33.3: 0.2271 },
  { 33.4: 0.2275 },
  { 33.5: 0.228 },
  { 33.6: 0.2283 },
  { 33.7: 0.2286 },
  { 33.75: 0.229 },
  { 33.8: 0.229 },
  { 33.9: 0.2294 },
  { 34: 0.23 },
  { 34.1: 0.2301 },
  { 34.2: 0.2305 },
  { 34.25: 0.231 },
  { 34.3: 0.2308 },
  { 34.4: 0.2312 },
  { 34.5: 0.232 },
  { 34.6: 0.2319 },
  { 34.7: 0.2323 },
  { 34.75: 0.232 },
  { 34.8: 0.2326 },
  { 34.9: 0.233 },
  { 35: 0.233 },
  { 35.1: 0.2337 },
  { 35.2: 0.234 },
  { 35.25: 0.234 },
  { 35.3: 0.2344 },
  { 35.4: 0.2347 },
  { 35.5: 0.235 },
  { 35.6: 0.2354 },
  { 35.7: 0.2358 },
  { 35.75: 0.236 },
  { 35.8: 0.2361 },
  { 35.9: 0.2365 },
  { 36: 0.237 },
  { 36.1: 0.2372 },
  { 36.2: 0.2375 },
  { 36.25: 0.238 },
  { 36.3: 0.2379 },
  { 36.4: 0.2382 },
  { 36.5: 0.239 },
  { 36.6: 0.2389 },
  { 36.7: 0.2392 },
  { 36.75: 0.239 },
  { 36.8: 0.2396 },
  { 36.9: 0.2399 },
  { 37: 0.24 },
  { 37.1: 0.2406 },
  { 37.2: 0.2409 },
  { 37.25: 0.241 },
  { 37.3: 0.2413 },
  { 37.4: 0.2416 },
  { 37.5: 0.242 },
  { 37.6: 0.2423 },
  { 37.7: 0.2426 },
  { 37.75: 0.243 },
  { 37.8: 0.2429 },
  { 37.9: 0.2433 },
  { 38: 0.244 },
  { 38.1: 0.2439 },
  { 38.2: 0.2442 },
  { 38.25: 0.244 },
  { 38.3: 0.2446 },
  { 38.4: 0.2449 },
  { 38.5: 0.245 },
  { 38.6: 0.2455 },
  { 38.7: 0.2459 },
  { 38.75: 0.246 },
  { 38.8: 0.2462 },
  { 38.9: 0.2465 },
  { 39: 0.247 },
  { 39.1: 0.2472 },
  { 39.2: 0.2475 },
  { 39.25: 0.248 },
  { 39.3: 0.2478 },
  { 39.4: 0.2481 },
  { 39.5: 0.249 },
  { 39.6: 0.2487 },
  { 39.7: 0.2491 },
  { 39.75: 0.249 },
  { 39.8: 0.2494 },
  { 39.9: 0.2497 },
  { 40: 0.25 },
  { 40.1: 0.2503 },
  { 40.2: 0.2506 },
  { 40.25: 0.251 },
  { 40.3: 0.2509 },
  { 40.4: 0.2512 },
  { 40.5: 0.252 },
  { 40.6: 0.2519 },
  { 40.7: 0.2522 },
  { 40.75: 0.252 },
  { 40.8: 0.2525 },
  { 40.9: 0.2528 },
  { 41: 0.253 },
  { 41.1: 0.2534 },
  { 41.2: 0.2537 },
  { 41.25: 0.254 },
  { 41.3: 0.254 },
  { 41.4: 0.2543 },
  { 41.5: 0.255 },
  { 41.6: 0.2549 },
  { 41.7: 0.2552 },
  { 41.75: 0.255 },
  { 41.8: 0.2555 },
  { 41.9: 0.2558 },
  { 42: 0.256 },
  { 42.1: 0.2564 },
  { 42.2: 0.2567 },
  { 42.25: 0.257 },
  { 42.3: 0.257 },
  { 42.4: 0.2573 },
  { 42.5: 0.258 },
  { 42.6: 0.2579 },
  { 42.7: 0.2582 },
  { 42.75: 0.258 },
  { 42.8: 0.2585 },
  { 42.9: 0.2587 },
  { 43: 0.259 },
  { 43.1: 0.2593 },
  { 43.2: 0.2596 },
  { 43.25: 0.26 },
  { 43.3: 0.2599 },
  { 43.4: 0.2602 },
  { 43.5: 0.261 },
  { 43.6: 0.2608 },
  { 43.7: 0.2611 },
  { 43.75: 0.261 },
  { 43.8: 0.2613 },
  { 43.9: 0.2616 },
  { 45.3: 0.2655 },
  { 45.4: 0.2658 },
  { 45.5: 0.266 },
  { 45.6: 0.2664 },
  { 45.7: 0.2666 },
  { 45.75: 0.267 },
  { 45.8: 0.2669 },
  { 45.9: 0.2672 },
];
